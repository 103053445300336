import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Garage3() {
    const BaseUrl = 'https://garage.btesisbul.com.tr';

    const [videos, setVideos] = useState([]);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0); // Şu anki video indeksini tutar
    const videoRef = React.createRef(); // Video elementine referans oluşturuyoruz

    // Mevcut videoları backend'den getir
    const fetchVideos = async () => {
        try {
            const response = await axios.get(`${BaseUrl}/videos/tv3`);
            setVideos(response.data);
            console.log("response", response.data);
        } catch (error) {
            console.error('Videoları getirme hatası:', error);
        }
    };

    // Sayfa ilk yüklendiğinde videoları getir
    useEffect(() => {
        fetchVideos();
    }, []);

    // Video bittiğinde bir sonraki videoya geç
    const handleVideoEnd = () => {
        setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length); // Bir sonraki videoya geç veya başa dön
    };

    // currentVideoIndex her değiştiğinde videoyu başlat
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.load(); // Yeni video kaynakları yüklensin
            videoRef.current.play(); // Oynatmaya başlasın
        }
    }, [currentVideoIndex]); // currentVideoIndex her değiştiğinde çalışacak

    return (
        <div className="container-fluid d-flex justify-content-center align-items-center vh-100" style={{ backgroundColor: '#000' }}>
            {videos.length > 0 ? (
                <div className="w-100">
                    <video
                        key={currentVideoIndex} // React'ın her video değişiminde yeniden render etmesini sağlamak için
                        className="w-100"
                        onEnded={handleVideoEnd} // Video bittiğinde bu fonksiyonu çağır
                        autoPlay
                        muted
                        ref={videoRef} // Video referansını ekledik
                    >
                        {/* Şu anki videoyu göster */}
                        <source src={`${BaseUrl}/videos/tv3/${videos[currentVideoIndex]}`} type="video/mp4" />
                        Tarayıcınız bu videoyu desteklemiyor.
                    </video>
                </div>
            ) : (
                <p className="text-white">Yüklü video bulunamadı.</p>
            )}
        </div>
    );
}
