import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Garage1 from './Garage1';
import Garage2 from './Garage2';
import Garage3 from './Garage3';
import Garage4 from './Garage4';
function App() {
  return (
      <BrowserRouter>
          <Routes>
                <Route path="/" element={<Garage1 />} />
                <Route path="/garage2" element={<Garage2 />} />
                <Route path="/garage3" element={<Garage3 />} />
                 <Route path="/garage4" element={<Garage4 />} />

          </Routes>
      </BrowserRouter>
  );
}

export default App;
